
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Filter } from '@/types/ListTypes';

@Component({
  name: 'SearchFilter',
})
export default class SearchFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  private inputValue = '';

  private applyFilter(): void {
    if (this.inputValue === '') {
      return;
    }

    this.filter.data = this.inputValue;
    this.filter.applied = this.inputValue !== '';
    this.$emit('fetchData');
  }
}
